import React from 'react'
import {Skeleton} from 'antd'
import {LicenseDetailsContext, LicenseDetailsProvider} from 'trello-shared-resources/dist/index'
import ThreadedCommentsSettingsDisplay from './ThreadedCommentsSettingsDisplay'

const ThreadedCommentsSettings = () => {
    return <LicenseDetailsProvider>
        <LicenseDetailsContext.Consumer>
            {(licenseDetails: any) => {
                if (licenseDetails.isLoading) return <Skeleton active={true}/>
                return <ThreadedCommentsSettingsDisplay licenseDetails={licenseDetails}/>
            }}
        </LicenseDetailsContext.Consumer>
    </LicenseDetailsProvider>
}

export default ThreadedCommentsSettings
