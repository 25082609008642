import React, {useEffect, useState} from 'react'
import {Skeleton} from 'antd'
import {getUserEmailForNotifications} from '../../../modules/Persistence'
import EmailNotificationsStep from './emailNotificationsStep/EmailNotificationsStep'
import OnboardingProperties from 'trello-shared-resources/dist/types/OnboardingProperties'
import MemberEmail from '../../../types/MemberEmail'
import {ThreadedCommentsEmailCheckoutSteps} from '../../../types/ThreadedCommentsEmailCheckoutSteps'
import UnauthorizedStep from 'trello-shared-resources/dist/components/onboarding/unauthorized/unauthorizedStep/UnauthorizedStep'

/**
 * Main component used to load data from db and show the proper page accordingly
 */
const EmailNotificationsCheckout = (props: OnboardingProperties) => {
    const [userEmail, setUserEmail] = useState<null | MemberEmail>(null)
    const [currentStep, setCurrentStep] = useState<ThreadedCommentsEmailCheckoutSteps>(ThreadedCommentsEmailCheckoutSteps.COLLECT)
    const [showErrorPage, setShowErrorPage] = useState(false)

    const licenseDetails = props.licenseDetails
    const trelloIframeContext = licenseDetails.trelloIframeContext

    useEffect(() => {
        if (trelloIframeContext && trelloIframeContext.getContext) {
            getUserEmailForNotifications(trelloIframeContext).then((result: any) => {
                setUserEmail(result)
                if(result && result.email) setCurrentStep(ThreadedCommentsEmailCheckoutSteps.SUCCESS)
            }).catch(() => {
                setShowErrorPage(true)
            })
        }
    }, [trelloIframeContext])

    if (showErrorPage) {
        return <UnauthorizedStep licenseDetails={licenseDetails}/>
    }

    if (licenseDetails.isLoading || userEmail === null) {
        return <Skeleton active={true}/>
    }

    return <EmailNotificationsStep licenseDetails={licenseDetails} setCurrentStep={setCurrentStep} currentStep={currentStep}/>
}

export default EmailNotificationsCheckout