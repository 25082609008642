import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import {useStyles} from './GetStartedStyles'
import Button from '@atlaskit/button'
import {
    getStartedButtonText,
    getStartedContent,
    getStartedContentSubtitle,
    getStartedTitle
} from '../onboarding/email-notifications-messages'
import {hasUserDismissMiniOnboarding, setUserDismissMiniOnboarding} from '../../../modules/TrelloPersistence'
import IconWithTitle from '../commonComponents/IconWithTitle'
import {EmailNotificationEventType, trackEmailNotificationEvent} from '../onboarding/EmailNotificationsUtils'
import {getUserEmailForNotifications} from '../../../modules/Persistence'
import {throwError} from '../../../modules/UIUtils'
import {useErrorHandler} from 'react-error-boundary'

/**
 * Mini Onboarding. Shown for "no-admin" users and for "admin" users that skipped the email notification onboarding
 */
const GetStarted = (props: { licenseDetails: any }) => {
    const classes = useStyles()
    const [isDismissed, setIsDismissed] = useState<boolean>(true)
    const [isUserSubscribed, setIsUserSubscribed] = useState<boolean>(true)
    const handleError = useErrorHandler()

    /**
     * Load init values
     * @param trelloIframeContext trello context
     */
    const showGetStarted = async (trelloIframeContext: any) => {
        setIsDismissed(await hasUserDismissMiniOnboarding(trelloIframeContext))
        const emailData = await getUserEmailForNotifications(trelloIframeContext)
        setIsUserSubscribed(emailData && emailData.email !== undefined)
    }

    useEffect(() => {
            const trelloIframeContext = props.licenseDetails.trelloIframeContext
            showGetStarted(trelloIframeContext).catch((error: any) => throwError(() => handleError(error), trelloIframeContext))
        }, // eslint-disable-next-line
        [props.licenseDetails.trelloIframeContext, props.licenseDetails])

    /**
     * Launches the mini onboarding modal window
     */
    const openMiniOnbardingModal = async () => {
        const trelloIframeContext = props.licenseDetails.trelloIframeContext
        trackEmailNotificationEvent(props.licenseDetails.trelloIframeContext.getContext(), EmailNotificationEventType.START)
        trelloIframeContext.modal({
            url: './index.html?apptype=miniOnboarding',
            height: 680,
            title: process.env.REACT_APP_TRELLO_APP_NAME,
            accentColor: '#EBEDF0',
            callback: () => {
                showGetStarted(trelloIframeContext).catch((error: any) => throwError(() => handleError(error), trelloIframeContext))
            }
        })
    }

    const closeIconHandler = () => {
        trackEmailNotificationEvent(props.licenseDetails.trelloIframeContext.getContext(), EmailNotificationEventType.REJECT)
        setUserDismissMiniOnboarding(props.licenseDetails)
        setIsDismissed(true)
    }

    return (
        <>
            {!isDismissed && !isUserSubscribed &&
            <Grid container spacing={0} className={classes.grid}>
                <Grid container alignContent="flex-end" direction="column">
                    <Box className={classes.closeIcon} onClick={closeIconHandler}>
                        <CrossIcon label="close"/>
                    </Box>
                </Grid>
                <IconWithTitle title={getStartedTitle}/>
                <Box>
                    <Typography className={classes.subtitle}>
                        {getStartedContentSubtitle}
                    </Typography>
                </Box>
                <Box>
                    <Typography className={classes.content}>
                        {getStartedContent}
                    </Typography>
                </Box>
                <Grid container alignContent="flex-end" direction="column">
                    <Box className={classes.boldElements}>
                        <Button appearance="primary" onClick={openMiniOnbardingModal}>{getStartedButtonText}</Button>
                    </Box>
                </Grid>
            </Grid>
            }
        </>
    )
}

export default GetStarted