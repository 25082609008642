import {Box, Grid, Typography} from '@material-ui/core'
import React from 'react'
import {useStyles} from 'trello-shared-resources/dist/components/onboarding/unpaid/unpaidText/UnpaidTextStyles'
import Button from '@atlaskit/button'
import {loginToAuth0} from 'trello-shared-resources/dist/services/LicenseService'
import {NotLoggedInOrahErrorProps} from '../../types/NotLoggedInOrahErrorProps'

const NotLoggedInOrahErrorText = (props: NotLoggedInOrahErrorProps) => {

    const classes = useStyles()

    const onClickAction = async () => {
        await loginToAuth0(props.licenseDetails.auth0Client)
        await props.licenseDetails.checkLicenseCapabilities()
        props.setNotLoggedInOrahUser(false)
    }

    return <Grid item xs={7}>
        <Grid container spacing={0} direction="column" alignItems="center" className={classes.smallContainer}>
            <Grid item xs={6} className={classes.smallWindowGrid}>
                <Typography className={classes.smallWindowTitle}>Houston, we have a problem</Typography>
                <Typography className={classes.smallWindowSubtitle}>
                    Oops! Your free license has expired. To extend your free license, just log in to your Orah account or create an account if you do not already have one.
                </Typography>
                <Box mt={3} className={classes.smallWindowBox}>
                    <Button appearance="primary" onClick={onClickAction}>Next</Button>
                </Box>
            </Grid>
        </Grid>
    </Grid>
}

export default NotLoggedInOrahErrorText