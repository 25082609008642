import errorImage from '../../assets/images/happy-bunch-4.svg'
import NotLoggedInOrahErrorText from './NotLoggedInOrahErrorText'
import {useStyles} from 'trello-shared-resources/dist/components/onboarding/unpaid/unpaidStep/UnpaidStepStyles'
import React from 'react'
import {Grid} from '@material-ui/core'
import OnboardingImage from 'trello-shared-resources/dist/components/onboarding/onboardingImage/OnboardingImage'
import {NotLoggedInOrahErrorProps} from '../../types/NotLoggedInOrahErrorProps'

const NotLoggedInOrahError = (props: NotLoggedInOrahErrorProps) => {

    const classes = useStyles()

    return <Grid container spacing={0} className={classes.smallGrid}>
        <OnboardingImage smallErrorMessageWindow={true} image={errorImage}
                         backgroundColor="rgba(195,131,245,0.16)" width="450px"/>
        <NotLoggedInOrahErrorText {...props}/>
    </Grid>
}

export default NotLoggedInOrahError