import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appContainer: {
            backgroundColor: '#f4f5f7',
            height: '100%',
            minHeight: '380px',
        }
    }
    )
)

export { useStyles }
