import React, {ChangeEvent, forwardRef, useEffect, useState} from 'react'
import TextArea from '@atlaskit/textarea'
import {ControlledTextAreaProperties} from '../../types/ControlledTextAreaProperties'

const ControlledTextArea = forwardRef(({ value, onChange, ...rest }: ControlledTextAreaProperties, commentEditorRef: any) => {

    const [cursor, setCursor] = useState(0)

    useEffect(() => {
        const input: any = commentEditorRef.current
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [commentEditorRef, cursor, value])

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setCursor(e.target.selectionStart)
        onChange && onChange(e)
    }

    return <TextArea ref={commentEditorRef} value={value} onChange={handleChange} {...rest} />
})

export default ControlledTextArea