import React from 'react'
import {Box, Tooltip} from '@material-ui/core'
import {useStyles} from './ReactionsStyle'
import {CommentReactionGrouped} from '../../types/CommentReactionGrouped'
import {SimpleTag as Tag} from '@atlaskit/tag'
import {ReactionsProps} from '../../types/ReactionsProps'

const Reactions = (props: ReactionsProps) => {

    const classes = useStyles()

    return <>
        <Box display="flex" flexDirection="row" flexWrap="wrap" className={classes.reactionContainer}>
            {props.comment.groupedReactions && props.comment.groupedReactions.map((reaction: CommentReactionGrouped, index: number) =>
                <Tooltip title={`${reaction.members} reacted with ${reaction.emojiName}`}
                         className={classes.tooltipPlacementTop} placement="top" key={index}
                         onClick={event => props.onEmojiClick(event, {
                             emoji: reaction.emoji,
                             names: [reaction.emojiName],
                             unified: '',
                             originalUnified: '',
                             activeSkinTone: 'neutral'
                         })}>
                    <Box className={classes.reaction}>
                        <Tag text={`${reaction.emoji}${reaction.count > 1 ? ` ${reaction.count}` : ''}`}
                             color="blueLight" appearance="rounded"/>
                    </Box>
                </Tooltip>
            )}
        </Box>
    </>
}

export default Reactions